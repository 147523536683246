import { getTheme } from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import { ChannelsProps, SegmentData } from 'types';

export const Channels: React.FC<ChannelsProps> = ({ series, data, tick }) => {
  const [channels, setChannels] = useState<number[]>([]);
  const theme = getTheme();
  console.log({ theme });

  useEffect(() => {
    if (series && series.length > 0) {
      setChannels(series.fields[0].values as number[]);
    } else {
      setChannels([]);
    }
  }, [series]);

  return (
    <div className={'channelContainer'}>
      {channels
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
        .map((c, i) => {
          const segment: SegmentData | undefined = data.find((s) => s.Channel === c);

          return (
            <div
              key={c}
              className={'channel'}
              style={{
                backgroundColor:
                  segment?.CallType === 0 && segment.Description === 'Down'
                    ? '#9CA3AF'
                    : segment?.CallType === 0 && segment.Description === 'Illegal Carrier'
                    ? '#F97316'
                    : segment?.CallType === 0 && segment.Description === 'BSI'
                    ? '#60A5FA'
                    : segment?.CallType === 0 && segment.Description === 'Control Channel'
                    ? '#D1D5DB'
                    : segment?.CallType === 1
                    ? '#F2CC0C'
                    : segment?.CallType === 2
                    ? '#73BF69'
                    : segment?.CallType === 3 || segment?.CallType === 6
                    ? '#3B82F6'
                    : segment?.CallType === 5 || segment?.CallType === 7
                    ? '#6B7280'
                    : '#FFF',
              }}
            >
              <div className="channel-header">Channel {c}</div>
              <div>
                {segment?.CallType === 1 || segment?.CallType === 2 ? (
                  <>
                    <div>{segment.RadioID}</div>
                    {segment.CallType === 1 && <div>{segment.Description}</div>}
                    {segment.CallType === 2 && <div>{segment.TargetGroup}</div>}
                  </>
                ) : (
                  <div>{segment?.Description}</div>
                )}
              </div>
              <div>{segment?.DT && (tick.diff(segment.DT, 'ms') / 1000.0).toFixed(1)}</div>
            </div>
          );
        })}
    </div>
  );
};
