import { useCallback, useRef } from 'react';

export function useTimer(): [(callback: (...args: any[]) => void, duration: number) => void, () => void] {
  const timer = useRef<NodeJS.Timeout>();

  function set(callback: (...args: any[]) => void, duration: number): void {
    if (timer.current) {
      global.clearTimeout(timer.current);
    }
    timer.current = global.setTimeout(callback, duration);
  }

  const clear = useCallback(() => {
    if (timer.current) {
      global.clearTimeout(timer.current);
    }
  }, []);

  return [set, clear];
}
