import React from 'react';
import { dateTimeAsMoment } from '@grafana/data';
import { TicksProps } from 'types';

export const Ticks: React.FC<TicksProps> = (props) => {
  const { scale } = props;
  const ticks = scale.ticks();

  return (
    <g className={'ticks'} transform={'translate(0, 18)'}>
      {ticks.map((tick: Date, n: number) => {
        const x = scale(tick);
        return (
          <text key={n} x={x} y={10} textAnchor={'middle'}>
            <tspan x={x} dy={0}>
              {`${dateTimeAsMoment(tick).format('MM/DD')}`}
            </tspan>
            <tspan x={x} dy={10}>
              {`${dateTimeAsMoment(tick).format('HH:mm:ss')}`}
            </tspan>
          </text>
        );
      })}
    </g>
  );
};
