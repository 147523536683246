import { DataFrame, TimeRange, dateTimeAsMoment } from '@grafana/data';
import { scaleLinear, scaleTime } from 'd3-scale';
import { useTimer } from 'hooks';
import { ceil, findLast } from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Bucket, Moment, ReplayAction, ReplayProps, ReplayState, SegmentData, SegmentFields } from 'types';
import { TimeSlider } from './time-slider';
import { css } from '@emotion/css';
import { BusyQueue } from './busy-queue';
import { Channels } from './channels';

export const ReplayPanel: React.FC<ReplayProps> = ({ data, timeRange, width }) => {
  const margin = useMemo(() => ({ top: 50, right: 35, bottom: 0, left: 35 }), []);
  const [state, dispatch] = useReducer(reducer, {
    buckets: [],
    busies: [],
    range: [0, width - margin.left - margin.right],
    timeScale: scaleTime()
      .domain([timeRange.from.toDate(), dateTimeAsMoment(timeRange.to).clone().add(999).toDate()])
      .range([0, width - margin.left - margin.right])
      .clamp(true),
    tick: dateTimeAsMoment(timeRange.from),
    playing: false,
    speed: 5,
    step: 100,
  });

  const [setTimer, clearTimer] = useTimer();

  const speedScale = useMemo(() => {
    return scaleLinear().domain([180, 160, 140, 120, 100, 80, 60, 40, 20, 5]).range([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  }, []);

  const play = useCallback((): void => {
    dispatch({ type: 'set_playing' });
  }, []);

  const gotoStart = useCallback((): void => {
    dispatch({ type: 'goto_start' });
  }, []);

  const gotoEnd = useCallback((): void => {
    dispatch({ type: 'goto_end' });
  }, []);

  const gotoBusyBackward = useCallback((): void => {
    dispatch({ type: 'goto_busy_backward' });
  }, []);

  const gotoBusyForward = useCallback((): void => {
    dispatch({ type: 'goto_busy_forward' });
  }, []);

  const skipBackward = useCallback((): void => {
    dispatch({ type: 'skip_backward' });
  }, []);

  const skipForward = useCallback((): void => {
    dispatch({ type: 'skip_forward' });
  }, []);

  const increaseDuration = useCallback((): void => {
    dispatch({ type: 'increase_speed' });
  }, []);

  const decreaseDuration = useCallback((): void => {
    dispatch({ type: 'decrease_speed' });
  }, []);

  const timerTick = useCallback((): void => {
    if (state.playing) {
      const tick = state.tick.clone().add(state.step);
      if (tick.isSameOrBefore(state.timeScale.invert(state.range[1]))) {
        dispatch({ type: 'set_tick', tick: tick });
      } else {
        dispatch({ type: 'set_playing' });
      }
    }
  }, [state.playing, state.tick, state.step, state.timeScale, state.range]);

  useEffect(() => {
    dispatch({ type: 'set_range', range: [0, width - margin.left - margin.right] });
  }, [width, margin.left, margin.right]);

  useEffect(() => {
    dispatch({ type: 'set_timerange', timeRange: timeRange });
  }, [timeRange]);

  useEffect(() => {
    const series: DataFrame[] = data.series;
    if (Array.isArray(series) && series.length === 2) {
      const frame: DataFrame = series[0];
      const fields = frame.fields;

      const buckets: Bucket[] = [];
      const bucketItems = 1000;
      const bucketTotal = ceil(frame.length / bucketItems);

      for (let bucket = 0; bucket < bucketTotal; bucket++) {
        let segments: SegmentData[] = [];
        let minDT: Moment = dateTimeAsMoment(Date.parse('0001-01-01'));
        let maxDT: Moment = dateTimeAsMoment(Date.parse('9999-12-31'));
        for (let row = bucket * bucketItems; row < (bucket + 1) * bucketItems && row < frame.length; row++) {
          let segment = {
            DT: dateTimeAsMoment(fields[SegmentFields.DT].values[row]),
            DTEnd: dateTimeAsMoment(fields[SegmentFields.DTEnd].values[row]),
            Duration: fields[SegmentFields.Duration].values[row],
            Channel: fields[SegmentFields.Channel].values[row],
            CallType: fields[SegmentFields.CallType].values[row],
            RadioID: fields[SegmentFields.RadioID].values[row],
            TargetGroup: fields[SegmentFields.TargetGroup].values[row],
            TargetRadioID: fields[SegmentFields.TargetRadioID].values[row],
            StatusKey: fields[SegmentFields.Status].values[row],
            Description: fields[SegmentFields.Description].values[row],
          };
          segments.push(segment);
          minDT = segment.DT.isBefore(minDT) ? segment.DT : minDT;
          maxDT = segment.DTEnd.isAfter(maxDT) ? segment.DTEnd : maxDT;
        }
        buckets.push({ minDT: minDT, maxDT: maxDT, segments: segments });
      }
      dispatch({ type: 'set_buckets', buckets: buckets });
    }
  }, [data.series]);

  useEffect(() => {
    if (state.playing) {
      setTimer(timerTick, speedScale.invert(state.speed));
    } else {
      clearTimer();
    }
  }, [state.playing, state.speed, speedScale, clearTimer, setTimer, timerTick]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, [clearTimer]);

  return (
    <>
      <TimeSlider
        adjustTick={dispatch}
        busies={state.busies.map((s) => ({ DT: s.DT, DTEnd: s.DTEnd }))}
        margin={margin}
        range={state.range}
        scale={state.timeScale}
        tick={state.tick}
        width={width}
      />
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        `}
      >
        <button
          type={'button'}
          className={'btn btn-secondary'}
          title={'Go to beginning'}
          onClick={gotoStart}
          disabled={state.buckets.length === 0}
        >
          <span className={'fa fa-step-backward'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-busies'}
          title={'Go to previous Busy'}
          disabled={state.busies.length === 0 || state.busies.filter((b) => state.tick.isAfter(b.DT)).length === 0}
          onClick={gotoBusyBackward}
        >
          <span className={'fa fa-fast-backward'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-secondary'}
          title={'Skip backwards 5 seconds'}
          onClick={skipBackward}
          disabled={state.buckets.length === 0}
        >
          <span className={'fa fa-fast-backward'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-secondary'}
          title={'Play'}
          onClick={play}
          disabled={state.buckets.length === 0}
        >
          <span className={state.playing ? 'fa fa-pause' : 'fa fa-play'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-secondary'}
          title={'Skip forwards 5 seconds'}
          onClick={skipForward}
          disabled={state.buckets.length === 0}
        >
          <span className={'fa fa-fast-forward'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-busies'}
          title={'Go to next Busy'}
          disabled={state.busies.length === 0 || state.busies.filter((b) => state.tick.isBefore(b.DT)).length === 0}
          onClick={gotoBusyForward}
        >
          <span className={'fa fa-fast-forward'} />
        </button>
        <button
          type={'button'}
          className={'btn btn-secondary'}
          title={'Go to ending'}
          onClick={gotoEnd}
          disabled={state.buckets.length === 0}
        >
          <span className={'fa fa-step-forward'} />
        </button>
        <span style={{ marginLeft: '3em' }}>
          <button type={'button'} className={'btn btn-secondary btn-mini'} onClick={decreaseDuration}>
            <span className={'fa fa-minus'} />
          </button>
          <input type={'number'} style={{ textAlign: 'center', width: '3em' }} value={state.speed} />
          <button type={'button'} className={'btn btn-secondary btn-mini'} onClick={increaseDuration}>
            <span className={'fa fa-plus'} />
          </button>
        </span>
      </div>
      <BusyQueue
        data={state.busies.filter((s) => state.tick.isSameOrAfter(s.DT) && state.tick.isSameOrBefore(s.DTEnd))}
        tick={state.tick}
      />
      <Channels
        series={data.series.length === 2 ? data.series[1] : null}
        data={state.buckets
          .filter((b) => state.tick.isSameOrAfter(b.minDT) && state.tick.isSameOrBefore(b.maxDT))
          .reduce<SegmentData[]>((pv, cv) => [...pv, ...cv.segments], [])
          .filter((s) => state.tick.isSameOrAfter(s.DT) && state.tick.isSameOrBefore(s.DTEnd))}
        tick={state.tick}
      />
    </>
  );
};

const reducer = (state: ReplayState, action: ReplayAction): ReplayState => {
  switch (action.type) {
    case 'increase_speed':
      return {
        ...state,
        speed: state.speed < 10 ? state.speed + 1 : state.speed,
      };
    case 'decrease_speed':
      return {
        ...state,
        speed: state.speed > 1 ? state.speed - 1 : state.speed,
      };
    case 'set_playing':
      return {
        ...state,
        playing: !state.playing,
      };
    case 'set_range':
      const range = action.range ?? [];
      return {
        ...state,
        range: range,
        timeScale: state.timeScale.range(range),
      };
    case 'set_buckets':
      const buckets = action.buckets ?? [];
      return {
        ...state,
        buckets: buckets,
        busies: buckets
          .reduce<SegmentData[]>((pv, cv) => [...pv, ...cv.segments], [])
          .filter((s) => (s.CallType === 1 || s.CallType === 2) && s.StatusKey !== 1),
        playing: false,
      };
    case 'set_tick':
      const tick = action.tick ?? dateTimeAsMoment(state.timeScale.invert(state.range[0]));
      return {
        ...state,
        tick: tick,
      };
    case 'set_timerange':
      const timeRange = action.timeRange as TimeRange;
      return {
        ...state,
        tick: dateTimeAsMoment(timeRange.from),
        timeScale: state.timeScale.domain([timeRange.from.toDate(), timeRange.to.add(999).toDate()]),
      };
    case 'goto_start':
      return {
        ...state,
        tick: dateTimeAsMoment(state.timeScale.invert(state.range[0])),
      };
    case 'goto_end':
      return {
        ...state,
        tick: dateTimeAsMoment(state.timeScale.invert(state.range[1])),
      };
    case 'goto_busy_backward':
      return {
        ...state,
        tick: findLast(state.busies, (b) => b.DT.isBefore(state.tick))?.DT ?? state.tick,
      };
    case 'goto_busy_forward':
      return {
        ...state,
        tick: state.busies.find((b) => b.DT.isAfter(state.tick))?.DT ?? state.tick,
      };
    case 'skip_backward':
      return {
        ...state,
        tick: state.tick.clone().subtract(5000),
      };
    case 'skip_forward':
      return {
        ...state,
        tick: state.tick.clone().add(5000),
      };
    default:
      throw Error('Unknown action.');
  }
};
