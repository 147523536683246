import { DataFrame, PanelProps, TimeRange } from '@grafana/data';
import { ScaleTime } from 'd3-scale';

export type Moment = moment.Moment;

export interface Bucket {
  minDT: Moment;
  maxDT: Moment;
  segments: SegmentData[];
}

export interface ReplayOptions {}

export interface ReplayProps extends PanelProps<ReplayOptions> {}

export interface ReplayState {
  buckets: Bucket[];
  busies: SegmentData[];
  range: number[];
  timeScale: ScaleTime<number, number>;
  tick: Moment;
  playing: boolean;
  speed: number;
  step: number;
}

export interface ReplayAction {
  type: string;
  buckets?: Bucket[];
  tick?: Moment;
  range?: number[];
  timeRange?: TimeRange;
}

export type BusyDate = {
  DT: Moment;
  DTEnd: Moment;
};

export enum SegmentFields {
  DT,
  DTEnd,
  Duration,
  Channel,
  CallType,
  RadioID,
  TargetGroup,
  TargetRadioID,
  Status,
  Description,
}

export interface SegmentData {
  DT: Moment;
  DTEnd: Moment;
  Duration: number;
  Channel: number;
  CallType: number;
  RadioID: number;
  TargetGroup?: string;
  TargetRadioID?: string;
  StatusKey: number;
  Description: string;
}

export interface BusyMarksProps {
  scale: ScaleTime<number, number>;
  busies: BusyDate[];
}

export interface BusyQueueProps {
  data: SegmentData[];
  tick: Moment;
}

export interface ChannelsProps {
  series: DataFrame | null;
  data: SegmentData[];
  tick: Moment;
}

export interface TicksProps {
  scale: ScaleTime<number, number>;
}

export interface TimeSliderProps {
  adjustTick: React.Dispatch<ReplayAction>;
  busies: BusyDate[];
  margin: Margin;
  range: number[];
  scale: ScaleTime<number, number>;
  tick: Moment;
  width: number;
}

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
