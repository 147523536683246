import React, { useEffect } from 'react';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';
import { TimeSliderProps } from 'types';
import { dateTimeAsMoment } from '@grafana/data';
import { BusyMarks } from './busy-marks';
import { Ticks } from './ticks';

export const TimeSlider: React.FC<TimeSliderProps> = ({ adjustTick, busies, margin, range, scale, tick, width }) => {
  useEffect(() => {
    function handleDrag(event: DragEvent, d: any): void {
      adjustTick({ type: 'set_tick', tick: dateTimeAsMoment(scale.invert(event.x)) });
    }

    select<SVGRectElement, unknown>('.track-overlay-rect').call(drag<SVGRectElement, unknown>().on('drag', handleDrag));
  }, [scale, adjustTick]);

  return (
    <React.Fragment>
      <svg width={width} height={120}>
        <g className={'slider'} transform={`translate(${margin.left} ${margin.top})`}>
          <rect className={'track-rect'} height={10} width={range[1] - range[0]} rx={5}></rect>
          <circle className={'handle'} r={10} cx={scale(tick)} transform={'translate(0 5)'}></circle>
          <rect className={'track-overlay-rect'} height={10} width={range[1] - range[0]}></rect>
          <BusyMarks scale={scale} busies={busies} />
          <text
            className={'tick-label'}
            textAnchor={'middle'}
            transform={'translate(0, -25)'}
            y={0}
            x={scale(tick)}
          >{`${tick.format('MM/DD/yyyy')}`}</text>
          <text className={'tick-label'} textAnchor={'middle'} transform={'translate(0, -25)'} y={15} x={scale(tick)}>
            {`${tick.format('HH:mm:ss.S')}`}
          </text>
          <Ticks scale={scale} />
        </g>
      </svg>
    </React.Fragment>
  );
};
