import React from 'react';
import { BusyMarksProps } from 'types';

export const BusyMarks: React.FC<BusyMarksProps> = ({ scale, busies }) => {
  return (
    <g>
      {busies.map((b) => {
        return (
          <rect
            key={b.DT.toDate().getTime()}
            className={'track-busy'}
            height={10}
            width={scale(b.DTEnd) - scale(b.DT)}
            x={scale(b.DT)}
            y={0}
          ></rect>
        );
      })}
    </g>
  );
};
