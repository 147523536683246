import React from 'react';
import { BusyQueueProps, SegmentData } from 'types';

export const BusyQueue: React.FC<BusyQueueProps> = (props) => {
  const { data, tick } = props;

  return (
    <div className={'busy-container'}>
      {data.map((s: SegmentData) => {
        return (
          <div key={s.DT.toDate().getTime()} className={'busy-queue'}>
            <div>
              {s.CallType === 1 || s.CallType === 2 ? (
                <>
                  <div>{s.RadioID}</div>
                  {s.CallType === 1 && <div>{s.Description}</div>}
                  {s.CallType === 2 && <div>{s.TargetGroup}</div>}
                </>
              ) : (
                <div>{s.Description}</div>
              )}
            </div>
            <div>{(tick.diff(s.DT, 'ms') / 1000.0).toFixed(1)}</div>
          </div>
        );
      })}
    </div>
  );
};
